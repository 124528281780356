import React from "react";
import classNames from "classnames";

import { Carousel, CaseStudySlide } from "@molecules";
import { Container, Text } from "@atoms";

const TestimonialCarousel = ({ copy, caseStudies, className: _className }) => {
  return (
    <section className={classNames("bg-white py-16 md:py-32", _className)}>
      <div className="flex flex-col space-y-12">
        {copy && (
          <div className="mx-auto max-w-3xl">
            <Text
              richText
              className="text-center prose-p:text-lg lg:prose-p:text-xl"
            >
              {copy}
            </Text>
          </div>
        )}
        <Container variant="xl">
          <div className="px-4 md:px-8">
            <Carousel maxVisible={1}>
              {caseStudies?.map(c => (
                <CaseStudySlide key={c.uid} {...c} />
              ))}
            </Carousel>
          </div>
        </Container>
      </div>
    </section>
  );
};

TestimonialCarousel.defaultProps = {};

export default TestimonialCarousel;
